<template>
  <div>
    <div
      class="modal fade"
      id="TranslateModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      v-bind="$attrs"
      ref="tranlateModal"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content tranlateModalContent">
          <!-- Modal Content -->
          <div class="container" style="height: 80vh">
            <!-- Loader -->
            <div v-if="isLoading">
              <LoaderComp />
            </div>

            <div v-else style="">
              <div
                class="px-4 pb-1"
                v-if="Object.entries(selectedLanguage).length"
              >
                <div
                  class="pt-3 LanguageContainer d-flex flex-column"
                  style="height: 95%"
                >
                  <!-- Header start -->
                  <div
                    class="mb-3 d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <span class="Header-Text">Translation</span>
                    </div>

                    <!-- close Modal -->
                    <div class="">
                      <span style="cursor: pointer" @click="CloseModal">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L13 13M13 1L1 13"
                            stroke="#535355"
                            stroke-width="2"
                            stroke-linecap="round"
                          /></svg
                      ></span>
                    </div>
                  </div>
                  <!-- Header End -->

                  <!-- Mid Section Start -->
                  <div class="mb-3">
                    <div class="mid-section d-flex align-items-center pb-3">
                      <span class="mb-0 mr-3 textStyle">Default Language</span>

                      <!-- Select Language start -->
                      <div class="dropdown selectLanguage w-50">
                        <button
                          class="btn dropdown-toggle w-50 text-left"
                          style="
                            background: transparent;
                            border: 1px solid #000000;
                          "
                          type="button"
                          id="dropdownLanguageButton"
                          data-toggle="dropdown"
                        >
                          <LazyImage
                            :src="selectedLanguage.flag"
                            :key="selectedLanguage.flag"
                            style="height: 30px; width: 30px"
                          />
                          <div class="d-inline">
                            <span>{{ selectedLanguage.langText }} </span>
                          </div>
                          <div class="float-right">
                            <div class="d-flex">
                              <a
                                style="cursor: pointer"
                                class="arrow-icon-down"
                              >
                                <i
                                  class="fa fa-caret-down"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </div>
                          </div>
                        </button>
                        <div
                          class="dropdown-menu w-50 mb-0 pb-0"
                          aria-labelledby="dropdownLanguageButton"
                        >
                          <div
                            class="dropdown-item"
                            style="cursor: pointer"
                            v-for="(lang, index) in GetAllLanguages"
                            :key="index"
                            @click="changeSelectedLanguage(lang)"
                          >
                            <div class="">
                              <span>{{ lang.langText }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Select Language end -->
                    </div>
                  </div>
                  <!-- Mid Section End -->

                  <!-- End Section start -->
                  <div
                    class="text-left d-flex flex-column flex-grow-1 px-3 py-2 ScrollDiv"
                    style="height: 100%"
                  >
                    <div class="w-100 h-100">
                      <div
                        class="d-flex h-100 w-100 justify-content-center align-items-center"
                        v-if="!selectedLanguage.messages.length"
                      >
                        <h4 class="">No Data found for selected Language</h4>
                      </div>

                      <div v-else>
                        <div class="row mb-3">
                          <div class="col-6">
                            <span class="textStyle">Default</span>
                          </div>
                          <div class="col-6">
                            <div class="d-flex align-items-center">
                              <img
                                :src="selectedLanguage.flag"
                                class="mr-3"
                                style="height: 30px; width: 30px"
                              />
                              <span class="textStyle"
                                >{{
                                  selectedLanguage.langText
                                }}
                                Translation</span
                              >
                            </div>
                          </div>
                        </div>

                        <div
                          class="row mb-3"
                          v-for="(
                            translate, index
                          ) in selectedLanguage.messages"
                          :key="index"
                        >
                          <div class="col-6">
                            <span>{{ translate.text }}</span>
                          </div>
                          <!-- <div class="col-6">
                              <input type="text" @input="EventHandler($event ,translate)"  :value="translate.convertedText" class="w-100 languageInput">
                            </div> -->
                          <div class="col-6">
                            <input
                              type="text"
                              v-model="translate.convertedText"
                              class="w-100 languageInput"
                            />
                          </div>

                          <div
                            style="border-bottom: 0.3px solid #e9e9e9"
                            class="px-5 pb-3 w-100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Section End -->

                  <!-- Save Button start -->
                  <div class="mt-2">
                    <button
                      class="btn text-white px-4 py-2"
                      style="background: #4d1b7e; border-radius: 50px"
                      @click="saveData"
                    >
                      <span v-if="!loadingSpinner">Save</span>
                      <div
                        v-else
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                  <!-- Save Button end -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoaderComp from "./LoaderComp.vue";
import axios from "axios";
import $ from "jquery";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: {
    LoaderComp,
  },
  data() {
    return {
      loadingSpinner: false,
      isLoading: false,
      selectedLanguage: {},
    };
  },
  computed: {
    ...mapGetters(["getAllLanguages", "GetTokenFromMetaTag", "getQuizID"]),
    GetAllLanguages() {
      return this.getAllLanguages;
    },
  },
  methods: {
    ...mapMutations(["setTranslation"]),
    OpenModal() {
      $("#TranslateModal").modal("show");
    },
    changeSelectedLanguage(lang) {
      this.selectedLanguage = { ...lang, messages: JSON.parse(lang.messages) };
    },
    async translateData() {
      this.isLoading = true;
      try {
        const data = {
          quizId: this.getQuizID,
          _token: this.GetTokenFromMetaTag,
        };
        const response = await axios.post(`/loadLanguage`, data);
        if (response.status == 200) {
          await this.setTranslation(response.data.translation);
          // this.selectedLanguage = { ...response.data.translation.selectedLanguage}
          this.selectedLanguage = {
            ...response.data.translation.selectedLanguage,
            messages: JSON.parse(
              response.data.translation.selectedLanguage.messages
            ),
          };
        }
      } catch (error) {
        this.$toasted.show("Error occured ", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async saveData() {
      this.loadingSpinner = true;
      try {
        let data = {
          selectedLanguage: JSON.stringify(this.selectedLanguage),
          quizId: this.getQuizID,
          _token: this.GetTokenFromMetaTag,
        };
        const response = await axios.post(`/saveQuizTranslation`, data);
        if (response.status == 200) {
          await this.setTranslation(response.data.data.translation);
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured ", {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.loadingSpinner = false;
        this.CloseModal();
      }
    },
    CloseModal() {
      $("#TranslateModal").modal("hide");
    },
  },
  mounted() {
    $(this.$refs.tranlateModal).on("show.bs.modal", this.translateData);
  },
};
</script>

<style scoped>
.tranlateModalContent {
  border-radius: 20px;
}
.Header-Text {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  color: #4d4950;
}
.mid-section {
  text-align: left;

  border-bottom: 0.3px solid #e9e9e9;
}
.textStyle {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #4d4950;
}

.ScrollDiv {
  overflow: hidden scroll;
  scroll-behavior: smooth;
}
.languageInput {
  background: #faf7fd;
  border: 0.2px solid #4d1b7e;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px 5px;
  color: #4d4950;
}

.selectLanguage .dropdown-toggle::after {
  display: none;
}
.selectLanguage .dropdown-toggle i {
  margin-top: 8px;
}
</style>
